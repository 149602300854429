<template>
  <page-content title="大屏展示">
    <div class="screen" v-bind:class="{'full-screen':fullScreen}">

      <ScreenYirui v-if="screen.type==1" :screen="screen" :valueMap="valueMap" :deviceId="deviceId"></ScreenYirui>
      <ScreenHillbo v-else :screen="screen" :valueMap="valueMap" :deviceId="deviceId" :langZh="langZh"></ScreenHillbo>

      <!--按钮区，全屏，中英文切换-->
      <div class="screen-actions">
        <a-button class="btn-full-screen" @click="clickFullScreen" type="default"
                  :icon="fullScreen?'fullscreen-exit':'fullscreen'"></a-button>
        <a-button style="color: #000" @click="langZh=!langZh">{{ langZh ? "中" : "EN" }}</a-button>

        <a-popover title="字体大小" placement="bottom">
          <template slot="content">
            <a-slider class="font-size-slider" :min="100" :max="300" :value="screen.fontSize"
                      :tooltipVisible="false" @change="fontSizeChange"/>
          </template>
          <a-button icon="font-size"></a-button>
        </a-popover>
      </div>

    </div>
  </page-content>
</template>

<script>
import BaseDeviceView from "../BaseDeviceView";
import ScreenUtil from "../../../utils/screen"

import ScreenHillbo from "./ScreenHillbo"
import ScreenYirui from "./ScreenYirui"

export default {
  extends: BaseDeviceView,
  components: {ScreenHillbo, ScreenYirui},
  data() {
    return {
      fullScreen: false,
      langZh: true,
      screen: {},
      paraArray: []
    }
  },
  computed: {},
  created() {
    this.$get(`web/iot/water/screen/${this.deviceId}`).then(suc => {
      let model = suc.data || {}
      if (model.notice) {
        let reg = new RegExp('\\n', "g")
        model.notice = model.notice.replace(reg, '<br/>')
      }
      this.screen = {
        ...model,
        fontSize: localStorage.getItem("SCREEN_FONT_SIZE") || 100,
      }
    })

    this.$get(`web/iot/water/screen/paras/${this.deviceId}`).then(suc => {
      this.paraArray = suc.data
    })
  },
  methods: {
    clickFullScreen() {
      this.fullScreen = !this.fullScreen
      if (this.fullScreen) {
        ScreenUtil.fullScreen()
      } else {
        ScreenUtil.exitScreen()
      }
    },
    fontSizeChange(value) {
      this.fontSize = value
      this.screen = {
        ...this.screen,
        fontSize: value
      }
      // this.screen.fontSize = value
      // this.screen.textColor = "#E17234"
      localStorage.setItem("SCREEN_FONT_SIZE", value)
    }
  }
}
</script>

<style lang="less" scoped>
.screen {
  position: relative;
  background: #000;
  font-size: 100px;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  //font-family: SimHei, STHeiti;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    object-fit: cover;
    opacity: 1;
  }


  .title {
    text-align: center;
    font-size: 50%;
    padding: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;

    div {
      padding: 5px 20px;
    }
  }

  .time {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
    font-size: 20%;
    padding: 10px 20px;
  }

  .item-bg {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }

  .content-row {
    display: flex;
    align-items: center;
    flex: 1;
    z-index: 1;

    .paras {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 3%;


      .data-item-2 {
        width: 48%;
        margin: 1%;
        font-size: 120%;
      }

      .data-item-3 {
        width: 31.3%;
        margin: 1%;
        font-size: 90%;
      }
    }

    .notice {
      width: 28%;
      align-self: start;
      margin-right: 10px;
      margin-top: 60px;

      .notice-title {
        text-align: center;
        padding: 15px 20px;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
        font-size: 22%;
      }

      .notice-content {
        text-align: left;
        padding: 15px 20px;
        font-size: 18%;
        min-height: 300px;
      }
    }


  }
}

.full-screen {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  position: fixed;
  font-size: 120px;
}

.screen-actions {
  position: absolute;
  display: flex;
  left: 10px;
  top: 10px;
  z-index: 1;
  opacity: 0.6;

  * {
    margin-right: 10px;
  }

  .btn-full-screen {
    border: 1px solid #777;
  }

  .font-size-slider {
    margin: 10px auto;
  }
}


</style>
